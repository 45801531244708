<template>
  <div v-if="isLoading" class="text-center">
    <BaseProgressSpinner />
  </div>
  <div v-else>
    <CourseTopicPreview v-for="topic in courseTopics" :key="topic.id" :courseTopic="topic" />
  </div>
</template>

<script>
import { ref } from "vue";
import useApi from "@use/useApi";
import CourseTopicPreview from "./CourseTopicPreview";

export default {
  components: { CourseTopicPreview },

  setup() {
    const courseTopics = ref(null);

    const { isLoading, docs } = useApi({
      module: "uc",
      method: "listCourseTopicsPreview",
      onMount: true,
      params: {
        page: 1,
        itemsPerPage: 6,
      },
      onSuccess: () => {
        courseTopics.value = docs.value.data;
      },
    });

    return {
      isLoading,
      courseTopics,
    };
  },
};
</script>

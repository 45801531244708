<template>
  <div class="mb-8">
    <div class="max-w-1024">
      <div class="flex justify-content-between align-items-center">
        <BaseHeadline size="1" fake="2" no-margin>
          {{ courseTopic.displayName }}
        </BaseHeadline>

        <div v-if="hasMoreCourses">
          <BaseButton :label="label" outlined @click="pushToTopic" />
        </div>
      </div>

      <BaseParagraph class="mt-3">
        {{ courseTopic.description }}
      </BaseParagraph>
    </div>

    <div class="text-center py-4" v-if="isLoading">
      <BaseProgressSpinner />
    </div>

    <div v-else>
      <BaseRow>
        <CourseListColumn v-for="course in courses" :key="course.id" :course="course" />
      </BaseRow>
    </div>
  </div>
</template>

<script>
import CourseListColumn from "@/modules/user-course-uc/components/CourseList/partials/CourseListColumn";
import { computed, ref } from "vue";
import useApi from "@use/useApi";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  components: { CourseListColumn },

  props: {
    courseTopic: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();
    const { t } = useI18n();

    const courses = ref(null);
    const hasMoreCourses = ref(false);

    const { isLoading, docs } = useApi({
      module: "uc",
      method: "listCourses",
      onMount: true,
      params: {
        page: 1,
        itemsPerPage: 4,
        courseTopicIds: [props.courseTopic.id],
      },
      onSuccess: () => {
        courses.value = docs.value.data;
        hasMoreCourses.value = docs.value.meta.total > 4;
      },
    });

    const pushToTopic = async () => {
      try {
        await router.push({
          name: "Courses",
          query: {
            courseTopicId: props.courseTopic.id,
          },
        });
      } catch {
        return;
      }
    };

    const label = computed(() => {
      return t("uc.startpage.showMore", {
        coursesCount: props.courseTopic.coursesCount,
      });
    });

    return {
      courses,
      isLoading,
      pushToTopic,
      hasMoreCourses,
      label,
    };
  },
};
</script>

<template>
  <div class="start-page flex justify-content-center align-items-center">
    <div class="w-12 xl:w-6 text-center py-8">
      <div class="mb-6">
        <img :src="logo" class="w-6 xl:w-5 h-auto" alt="Logo" />
      </div>

      <BaseParagraph>
        {{ $t("uc.startpage.description") }}
      </BaseParagraph>
    </div>
  </div>

  <CourseTopicsPreview />
</template>

<script>
import logo from "@/assets/img/logo.svg";
import { useStore } from "vuex";
import CourseTopicsPreview from "./partials/CourseTopicsPreview";

export default {
  name: "StartPage",

  components: { CourseTopicsPreview },

  setup() {
    const store = useStore();
    const isAuthenticated = store.getters["usm/auth/isAuthenticated"];

    return { isAuthenticated };
  },

  computed: {
    logo() {
      return logo;
    },
  },

  methods: {
    async goToRegister() {
      try {
        await this.$router.push({ name: "Register" });
      } catch {
        return;
      }
    },
    async goToLogin() {
      try {
        await this.$router.push({ name: "Login" });
      } catch {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.start-page {
  min-height: 70vh;
}
</style>
